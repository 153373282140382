export const gallery = [
  { category: 'Widoki', path: '/zaciszenadwoda-zew-1.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-2.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-3.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-4.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-5.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-6.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-7.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-8.jpg' },
  { category: 'Widoki', path: '/zaciszenadwoda-zew-9.jpg' },
  { category: 'Sala', path: '/zaciszenadwoda-wew-1.jpg' },
  { category: 'Sala', path: '/zaciszenadwoda-wew-2.jpg' },
  { category: 'Sala', path: '/zaciszenadwoda-wew-3.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-4.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-5.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-6.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-7.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-8.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-9.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-10.jpg' },
  { category: 'Pokoje', path: '/zaciszenadwoda-wew-11.jpg' }
];
